<template>
  <div
    class="m"
    :style="[{ 'border-bottom': val == 'yes' ? '1px solid #e3f3ff' : '0' }]"
  >
    <div class="box">
      <div class="left">
        <SvgIcon
          @click="touxiangBtn"
          iconClass="logo"
          style="font-size: 22.9333vw; cursor: pointer; margin-right: 2.6667vw"
        ></SvgIcon>
        <div class="txt">
          <p>Need Assistance with your Order ?</p>
          <span
            >please email
            <a href="mailto:order@moominvalleypark-booking.com"
              >order@moominvalleypark-booking.com</a
            ></span
          >
        </div>
      </div>

      <SvgIcon
        style="font-size: 5.3333vw"
        @click="privacyDrawer = true"
        iconClass="unfold"
      ></SvgIcon>
    </div>

    <!-- 询问框 -->
    <div class="the-query-box">
      <el-dialog
        :visible.sync="tishiLoginDialog"
        width="91.4667vw"
        :before-close="handleClose"
      >
        <span>Are you sure you want to leave this page?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="yesClick">Leave</el-button>
          <el-button type="primary" @click="tishiLoginDialog = false"
            >Stay</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- 右侧 隐私政策 抽屉 -->
    <div class="privacyDrawer">
      <el-drawer
        :visible.sync="privacyDrawer"
        direction="rtl"
        :before-close="handleClose"
      >
        <div class="yszc">
          <ul>
            <li @click="aLianjieFn2('m_service')">
              <p>Terms of Service</p>
              <i class="el-icon-arrow-right"></i>
            </li>
            <li @click="aLianjieFn2('m_privacy')">
              <p>Privacy Policy</p>
              <i class="el-icon-arrow-right"></i>
            </li>
            <!-- <li @click="aLianjieFn2('m_notice')">
              <p>Notice</p>
              <i class="el-icon-arrow-right"></i>
            </li> -->
            <li @click="aLianjieFn2('m_contact')">
              <p>Contact Us</p>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
export default {
  data() {
    return {
      privacyDrawer: false, // 隐私政策抽屉
      tishiLoginDialog: false, // 弹窗询问
    };
  },
  props: ["dlogBr"],
  methods: {
    aLianjieFn2(val) {
      this.privacyDrawer = false;
      this.$router.push({ path: val });
    },
    // 抽屉关闭
    handleClose() {
      this.privacyDrawer = false;
    },
    // 头像点击事件
    touxiangBtn() {
      if (this.dlogBr) {
        this.tishiLoginDialog = true;
      } else {
        this.$router.push({
          path: "/m_home",
        });
      }
    },
    // 询问框确定按钮
    async yesClick() {
      // console.log("yes");
      this.tishiLoginDialog = false;
      this.$router.push({
        path: "/m_home",
      });
    },
    handleClose(done) {
      done();
    },
  },
  props: {
    val: String,
    dlogBr: Boolean,
  },
  components: {
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
.m {
  width: 100%;
  height: 14.9333vw;
  background-color: #fff;

  .box {
    background-color: #fff;
    display: flex;
    height: 100%;
    margin: 0 auto;
    padding: 0 4.2667vw;
    justify-content: space-between;
    align-items: center;
    /deep/.el-icon-s-unfold:before {
      content: "\e7bc";
      font-size: 6.6667vw;
    }
    .left {
      display: flex;
      align-items: center;
      .txt {
        font-size: 3.2vw;
        p {
          font-weight: 700;
        }
        a {
          font-weight: 700;
          color: #007bac;
          white-space: nowrap;
        }
      }
    }
  }
  .svg-icon {
    height: 100%;
  }
}

.the-query-box {
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;

    font-size: 5.3333vw;
    color: #12334c;
    margin-bottom: 8vw;
    span {
      word-break: break-word;
    }
  }
  /deep/.el-dialog__footer {
    padding: 0;
  }
  /deep/.el-button {
    width: 37.8667vw;
    // height: 42px;
    padding: 3.2vw 0;
    font-size: 4.2667vw;
  }
  /deep/.dialog-footer {
    // width: 348px;
    display: flex;
    justify-content: space-between;
  }

  /deep/.el-dialog {
    padding: 8vw 5.3333vw;
    border-radius: 12px;
    margin-top: 35vh !important;
  }
  /deep/.el-icon-close:before {
    content: "";
  }
  /deep/.el-button--default {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #12334c;
  }
  /deep/.el-button--primary {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #fff;
    background-color: #12334c;
  }
}

// 隐私政策 抽屉
/deep/.privacyDrawer {
  .el-drawer {
    width: 65.8667vw !important;
    background-color: #12334c;
    color: #fff;

    .el-drawer__header {
      padding: 17px 17px 0;
      margin-bottom: 24px;
    }

    .el-drawer__body {
      padding: 0 16px;
      .yszc {
        // width: 235px;
        // height: 178px;
        border-radius: 12px;
        ul {
          li {
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 10.6667vw;
            cursor: pointer;
            font-size: 4.2667vw;
            border-bottom: 1px solid #40acff2a;
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .el-icon-close:before {
      content: "\e6db";
      color: #fff;
      font-size: 6.6667vw;
    }
  }
}
</style>